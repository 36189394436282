export const documentTypes = [
  {
    name: 'NATIONAL_ID',
    iso_code: 'ES'
  },
  {
    name: 'PASSPORT',
    iso_code: 'OT'
  },
  {
    name: 'DRIVING_LICENSE',
    iso_code: 'OT'
  }
]
